import {
  UserModel,
  UserSlimModel,
  baseModelTrackByFn,
} from '@abb-procure/data';
import {
  Component,
  EventEmitter,
  Output,
  booleanAttribute,
  computed,
  input,
  numberAttribute,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TagComponent } from '../tag/tag.component';

export type Displayable = {
  uuid: string;
  getDisplay(): string;
};

@Component({
  selector: 'procure-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  standalone: true,
  imports: [TagComponent, MatTooltipModule],
})
export class TagListComponent {
  @Output() readonly clear = new EventEmitter<Displayable | string>();
  @Output() readonly selectActive = new EventEmitter<Displayable | string>();

  tags = input<(Displayable | string)[] | null>(null);
  users = input<(UserModel | null)[] | (UserSlimModel | null)[] | null>(null);

  maxVisible = input<number, number | string>(0, {
    transform: numberAttribute,
  });

  clearable = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  displayLabel = input<string>();
  active = input<(Displayable | string) | null>(null);

  selectable = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  readonly limitedTags = computed(() => {
    const tags = this.tags();
    const maxVisible = this.maxVisible();

    if (!tags) {
      return [];
    }

    const limit = maxVisible > 0 ? maxVisible : tags?.length;
    return tags.slice(0, limit);
  });

  readonly ellipsis = computed(() => {
    const tags = this.tags();
    const maxVisible = this.maxVisible();

    if (!tags || !maxVisible) {
      return false;
    }

    return tags.length > maxVisible;
  });

  isString(value: unknown): value is string {
    return typeof value === 'string';
  }

  isActive(item: Displayable | string): boolean {
    const active = this.active();

    if (this.isString(item) || this.isString(active)) {
      return item === active;
    }

    return item?.uuid === active?.uuid;
  }

  trackByFn(index: number, item: Displayable | string | null): string | null {
    if (item === null) {
      return null;
    }

    return typeof item === 'string' ? item : baseModelTrackByFn(index, item);
  }

  protected getDisplay(item: Displayable | string): string {
    const displayLabel = this.displayLabel();

    if (this.isString(item)) {
      return item;
    }

    if (displayLabel) {
      return `${item[displayLabel as keyof typeof item]}`;
    }

    return item.getDisplay();
  }
}
