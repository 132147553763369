import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  booleanAttribute,
  input,
} from '@angular/core';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';

@Component({
  standalone: true,
  selector: 'procure-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  imports: [LoadingIndicatorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @HostBinding('class.card--condensed')
  get isCondensed(): boolean {
    return this.condensed();
  }

  @HostBinding('class.card--flat')
  get isFlat(): boolean {
    return this.flat();
  }

  @HostBinding('class.card--accent')
  get isAccent(): boolean {
    return this.accent();
  }

  cover = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  loadingDiameter = input<number>(40);
  condensed = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  loading = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  flat = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  accent = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  opaque = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });
}
