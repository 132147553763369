import { PreferencesState } from '@abb-procure/data';
import {
  Component,
  HostBinding,
  booleanAttribute,
  computed,
  inject,
  input,
} from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { CopyButtonComponent } from '../copy-button/copy-button.component';
import { EntityBadgeComponent } from '../entity-badge/entity-badge.component';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';

@Component({
  selector: 'procure-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  standalone: true,
  imports: [
    LoadingIndicatorComponent,
    ButtonComponent,
    EntityBadgeComponent,
    CopyButtonComponent,
  ],
})
export class MenuComponent {
  @HostBinding('attr.title') titleAttr = '';

  title = input<string>();

  entityNumber = input<string | null>(null);

  icons = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  isLoading = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  bottom = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  protected copyLink = computed(() => {
    console.log(`${window.location.origin}/${this.entityNumber()}`);
    return `${window.location.origin}/${this.entityNumber()}`;
  });

  protected expanded = inject(PreferencesState).isNavigationExpanded;
}
