import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  booleanAttribute,
  input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'procure-show-field',
  templateUrl: './show-field.component.html',
  styleUrls: ['./show-field.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatTooltipModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ShowFieldComponent {
  label = input<string>('');
  hint = input<string>('');

  invalid = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  required = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  showMode = input<boolean, boolean | string>(true, {
    transform: booleanAttribute,
  });

  hideLabel = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  statusColor = input<string | null | undefined>(null);
}
