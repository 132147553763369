import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  booleanAttribute,
  input,
  signal,
} from '@angular/core';
import { InitialsPipe } from '../../pipes/initials.pipe';

@Component({
  selector: 'procure-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  standalone: true,
  imports: [InitialsPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  @HostBinding('class.avatar--sharp')
  sharp = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  @HostBinding('class.avatar--error')
  get hasError(): boolean {
    return this.error() || !this.avatar();
  }

  name = input<string | null>(null);
  avatar = input<string | null>(null);

  error = signal(false);

  onImageError(): void {
    this.error.set(true);
  }
}
