import {
  ChangeDetectionStrategy,
  Component,
  booleanAttribute,
  input,
} from '@angular/core';

@Component({
  selector: 'procure-number-badge',
  templateUrl: './number-badge.component.html',
  styleUrls: ['./number-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NumberBadgeComponent {
  value = input.required<number>();
  label = input<string>();

  small = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  clickable = input<boolean, boolean | string>(true, {
    transform: booleanAttribute,
  });
}
