export enum EntityIdAliasPrefixes {
  PriceEntry = 'PE',
  Project = 'PROJ',
  ProjectExpediting = 'PEX',
  PurchaseRequisition = 'PREQ',
  Rfq = 'RFQ',
  RfRFQ = 'RFRFQ',
  Supplier = 'SUP',
  Quotation = 'QUOT',
  TemplateRfrfq = 'TRFRFQ',
  Category = 'CATM',
  Strategy = 'STRAT',
  User = 'USER',
}
