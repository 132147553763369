import {
  ChangeDetectionStrategy,
  Component,
  booleanAttribute,
  inject,
  input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardService } from '../../services/clipboard.service';

@Component({
  selector: 'procure-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrl: './copy-button.component.scss',
  standalone: true,
  imports: [MatTooltipModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyButtonComponent {
  value = input<string | null>();
  tooltip = input<string | null>();

  isUrl = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
  });

  private readonly clipboardService = inject(ClipboardService);

  protected copy(): void {
    const value = this.value();
    const isUrl = this.isUrl();

    if (!value && !isUrl) {
      return;
    }

    if (isUrl) {
      this.clipboardService.copy(value ?? '', true);
    } else if (value) {
      this.clipboardService.copy(value);
    }
  }
}
