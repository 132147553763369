export enum QuotationSourcingScopeColumns {
  Entity = 'Entity',
  TotalQuotationPrice = 'Total Quotation Price',
  TotalQuotationPriceUsd = 'Total Quotation Price (USD)',
  OfferedLeadTime = 'Offered Lead Time',
  IncotermsType = 'Incoterms Type',
  IncotermComment = 'Incoterm Comment',
  ValidUntil = 'Valid Until',
  Warranty = 'Warranty',
  PaymentTerms = 'Payment Terms',
  CommercialCompliance = 'Commercial Compliance',
  TechnicalCompliance = 'Technical Compliance',
  LogisticalCompliance = 'Logistical Compliance',
  Status = 'Status',
}

export const DEFAULT_QUOTATION_SOURCING_SCOPE_COLUMNS: (keyof typeof QuotationSourcingScopeColumns)[] =
  [
    'Entity',
    'TotalQuotationPrice',
    'OfferedLeadTime',
    'IncotermsType',
    'Status',
  ];

export enum ContactsColumns {
  Primary = 'Primary',
  FirstName = 'First Name',
  LastName = 'Last Name',
  Email = 'Email',
  Function = 'Function',
  Telephone = 'Telephone',
  Mobile = 'Mobile',
  Emails = 'Emails',
  Rfqs = 'RFQs',
  ResponseTime = 'Response Time',
  LastResponse = 'Last Response',
  Expiration = 'Expiration',
  Note = 'Note',
  IsArchived = 'Is Archived',
}

export const DEFAULT_CONTACTS_COLUMNS: (keyof typeof ContactsColumns)[] = [
  'Primary',
  'FirstName',
  'LastName',
  'Email',
  'Rfqs',
  'Expiration',
];

export enum IncidentsColumns {
  Url = 'Url',
  Name = 'Name',
  MdfCode = 'MDF Code',
  Status = 'Status',
  Impact = 'Impact',
  Created = 'Created',
  Resolved = 'Resolved',
}

export const DEFAULT_INCIDENTS_COLUMNS: (keyof typeof IncidentsColumns)[] = [
  'Url',
  'Name',
  'MdfCode',
  'Status',
  'Impact',
  'Created',
  'Resolved',
];

export enum SupplierManagementColumns {
  SmVendorId = 'SM Vendor ID',
  ErpVendorId = 'ERP Vendor ID',
  Guid = 'GUID',
  Company = 'Company',
  Country = 'Country',
  City = 'City',
  Contacts = 'Contacts',
  LocalDivisionAssignments = 'Local Division Assignments',
  Qualification = 'Qualification',
  Registration = 'Registration',
  Active = 'Is Active',
  Internal = 'Is Internal',
}

export const DEFAULT_SUPPLIER_MANAGEMENT_COLUMNS: (keyof typeof SupplierManagementColumns)[] =
  [
    'SmVendorId',
    'ErpVendorId',
    'Guid',
    'Company',
    'Country',
    'City',
    'Contacts',
    'LocalDivisionAssignments',
    'Qualification',
    'Registration',
  ];

export enum CategoryManagementColumns {
  Name = 'Name',
  ResponsibleUsers = 'Responsible Users',
  Countries = 'Countries',
  ProductGroups = 'Product Groups',
  MdfCodes = 'MDF Codes',
  Organisations = 'Organisations',
  Validity = 'Validity',
  Status = 'Status',
  ParentCategory = 'Parent Category',
  ParentCreator = 'Parent Creator',
  ParentStatus = 'Parent Status',
}

export const DEFAULT_CATEGORY_MANAGEMENT_COLUMNS: (keyof typeof CategoryManagementColumns)[] =
  [
    'Name',
    'ResponsibleUsers',
    'Countries',
    'ProductGroups',
    'MdfCodes',
    'Organisations',
    'Validity',
    'Status',
    'ParentCategory',
  ];

export enum TemplateManagementColumns {
  Name = 'Name',
  Category = 'Category',
  Owner = 'Owner',
  Countries = 'Countries',
  ProductGroups = 'Product Groups',
  MdfCodes = 'MDF Codes',
  Organisations = 'Organisations',
  Keywords = 'Keywords',
  Status = 'Status',
}

export const DEFAULT_TEMPLATE_MANAGEMENT_COLUMNS: (keyof typeof TemplateManagementColumns)[] =
  [
    'Name',
    'Category',
    'Owner',
    'Countries',
    'ProductGroups',
    'MdfCodes',
    'Organisations',
    'Keywords',
    'Status',
  ];

export enum SupplierStrategyTaskColumns {
  Strategy = 'Strategy',
  StrategyStatus = 'Strategy Status',
  StrategyDimension = 'Strategy Dimension',
  Task = 'Task',
  TaskResponsible = 'Task Responsible',
  TaskDueDate = 'Task Due Date',
  TaskStatus = 'Task Status',
}

export const DEFAULT_SUPPLIER_STRATEGY_COLUMNS: (keyof typeof SupplierStrategyTaskColumns)[] =
  [
    'Strategy',
    'StrategyStatus',
    'StrategyDimension',
    'Task',
    'TaskResponsible',
    'TaskDueDate',
    'TaskStatus',
  ];

export const SupplierStrategySourcingEventsColumns = {
  RfqNumber: 'RFQ Number',
  RfqStatus: 'RFQ Status',
  DueDate: 'Due Date',
  Purchaser: 'Purchaser',
  Created: 'Created',
  RfrfqNumber: 'RFRFQ Number',
  RfrfqStatus: 'RFRFQ Status',
  RfrfqName: 'RFRFQ Name',
  ProjectStatus: 'Project Status',
  ProjectNumber: 'Project Number',
  LocalDivision: 'Local Division',
  QuotationNumber: 'Quotation Number',
  QuotationStatus: 'Quotation Status',
  QuotationValidUntil: 'Quotation Valid Until',
  TotalQuotationPrice: 'Total Quotation Price',
  RfrfqRanking: 'RFRFQ Ranking',
};

export const DEFAULT_SUPPLIER_STRATEGY_SOURCING_EVENTS_COLUMNS: (keyof typeof SupplierStrategySourcingEventsColumns)[] =
  [
    'RfqNumber',
    'RfqStatus',
    'DueDate',
    'Purchaser',
    'Created',
    'RfrfqNumber',
    'RfrfqStatus',
    'RfrfqName',
    'ProjectStatus',
    'ProjectNumber',
    'LocalDivision',
    'QuotationNumber',
    'QuotationStatus',
    'QuotationValidUntil',
    'TotalQuotationPrice',
    'RfrfqRanking',
  ];

export enum CategoryMonitoringColumns {
  Entity = 'Entity',
  Template = 'Template',
  Status = 'Status',
  Organisation = 'Organisation',
  Purchaser = 'Purchaser',
  DueDate = 'Due Date',
  DeliveryDate = 'Delivery Date',
  OfferValidityDate = 'Offer Validity Date',
  MdfCode = 'MDF Code',
  SourcingEventBudget = 'Sourcing Event Budget',
  TargetPrice = 'Target Price',
  LeadingQuotationBudget = 'Leading Quotation Budget',
  CompetitiveBiddingStatus = 'Competitive Bidding Status',
}

export const DEFAULT_CATEGORY_MONITORING_COLUMNS: (keyof typeof CategoryMonitoringColumns)[] =
  [
    'Entity',
    'Template',
    'Status',
    'Organisation',
    'Purchaser',
    'DueDate',
    'DeliveryDate',
    'OfferValidityDate',
    'MdfCode',
    'SourcingEventBudget',
    'TargetPrice',
    'LeadingQuotationBudget',
    'CompetitiveBiddingStatus',
  ];

export enum LegalContractsColumns {
  Title = 'Title',
  Regions = 'Regions',
  Commodities = 'Commodities',
  Owner = 'Owner',
  Effective = 'Effective',
  Amount = 'Amount',
  Status = 'Status',
}

export const DEFAULT_LEGAL_CONTRACTS_COLUMNS: (keyof typeof LegalContractsColumns)[] =
  ['Title', 'Regions', 'Commodities', 'Owner', 'Effective', 'Amount', 'Status'];

export enum StrategyManagementColumns {
  Name = 'Name',
  Organisations = 'Organisations',
  Leaders = 'Leaders',
  Regions = 'Regions',
  MdfCodes = 'MDF Codes',
  Actions = 'Open Actions',
  ExpirationDate = 'Expiration Date',
  Status = 'Status',
}

export const DEFAULT_STRATEGY_MANAGEMENT_COLUMNS: (keyof typeof StrategyManagementColumns)[] =
  [
    'Name',
    'Organisations',
    'Leaders',
    'Regions',
    'MdfCodes',
    'ExpirationDate',
    'Status',
  ];

export enum QuotationsByStatusColumns {
  Project = 'Project',
  Rfrfq = 'RFRFQ',
  Rfq = 'RFQ',
  Quotation = 'Quotation',
  Status = 'Status',
  MdfCode = 'MDF Code',
  Country = 'Country',
  Purchaser = 'Purchaser',
}

export const DEFAULT_QUOTATIONS_BY_STATUS_COLUMNS: (keyof typeof QuotationsByStatusColumns)[] =
  ['Quotation', 'Status', 'MdfCode', 'Purchaser'];

export enum ResourceIncotermsColumns {
  Name = 'Name',
  Code = 'Code',
}

export const DEFAULT_RESOURCE_INCOTERMS_COLUMNS: (keyof typeof ResourceIncotermsColumns)[] =
  ['Name', 'Code'];

export enum ResourceTermsAndConditionsColumns {
  Name = 'Name',
  Url = 'Url',
  IsAvailable = 'Available',
  Actions = 'Actions',
}

export const DEFAULT_RESOURCE_TERMS_AND_CONDITIONS_COLUMNS: (keyof typeof ResourceTermsAndConditionsColumns)[] =
  ['Name', 'Url', 'IsAvailable', 'Actions'];

export enum LocalDivisionUsersColumns {
  Selected = 'Selected',
  Name = 'Name',
  Email = 'Email',
  Department = 'Department',
  Telephone = 'Telephone',
  Mobile = 'Mobile',
  Role = 'Role',
  Active = 'Active',
  Tsp = 'TSP Access',
  Status = 'Access Status',
  ReviewStatus = 'Review Status',
  Reviewer = 'Reviewer',
  ReviewDate = 'Review Date',
  NextReview = 'Next Review',
  Actions = 'Actions',
}

export const DEFAULT_LOCAL_DIVISION_USERS_COLUMNS: (keyof typeof LocalDivisionUsersColumns)[] =
  [
    'Selected',
    'Name',
    'Email',
    'Role',
    'Active',
    'Tsp',
    'Status',
    'ReviewStatus',
    'ReviewDate',
    'Actions',
  ];

export enum ResourcePaymentTermsColumns {
  Name = 'Name',
}

export const DEFAULT_RESOURCE_PAYMENT_TERMS_COLUMNS: (keyof typeof ResourcePaymentTermsColumns)[] =
  ['Name'];

export enum ResourceCurrenciesColumns {
  Name = 'Name',
  Code = 'Code',
  Status = 'Status',
}

export const DEFAULT_RESOURCE_CURRENCIES_COLUMNS: (keyof typeof ResourceCurrenciesColumns)[] =
  ['Name', 'Code', 'Status'];

export enum ResourceVariablesColumns {
  Code = 'Code',
  Name = 'Name',
  Value = 'Value',
}

export const DEFAULT_RESOURCE_VARIABLES_COLUMNS: (keyof typeof ResourceVariablesColumns)[] =
  ['Code', 'Name', 'Value'];

export enum ResourceExchangeRatesColumns {
  Name = 'Name',
  Code = 'Code',
  Unit = 'Unit',
  ClosingRate = 'Closing Rate',
}

export const DEFAULT_RESOURCE_EXCHANGE_RATES_COLUMNS: (keyof typeof ResourceExchangeRatesColumns)[] =
  ['Name', 'Code', 'Unit', 'ClosingRate'];

export enum OrganisationManagersColumns {
  Selected = 'Selected',
  Name = 'Name',
  Status = 'Status',
  ReviewStatus = 'Review Status',
  Reviewer = 'Reviewer',
  ReviewDate = 'Review Date',
  NextReview = 'Next Review',
  Actions = 'Actions',
}

export const DEFAULT_ORGANISATION_MANAGERS_COLUMNS: (keyof typeof OrganisationManagersColumns)[] =
  ['Selected', 'Name', 'Status', 'ReviewStatus', 'ReviewDate', 'Actions'];

export enum ResourceMdfCodesColumns {
  Code = 'Code',
  Name = 'Name',
}

export const DEFAULT_RESOURCE_MDF_CODES_COLUMNS: (keyof typeof ResourceMdfCodesColumns)[] =
  ['Code', 'Name'];

export enum UsersColumns {
  Name = 'Name',
  Email = 'Email',
  LocalDivision = 'Local Division',
  Active = 'Active',
  Actions = 'Actions',
}

export const DEFAULT_USERS_COLUMNS: (keyof typeof UsersColumns)[] = [
  'Name',
  'Email',
  'LocalDivision',
  'Active',
  'Actions',
];

export enum ResourceLinksColumns {
  Icon = 'Icon',
  Name = 'Name',
  Description = 'Description',
  Url = 'URL',
}

export const DEFAULT_RESOURCE_LINKS_COLUMNS: (keyof typeof ResourceLinksColumns)[] =
  ['Icon', 'Name', 'Description', 'Url'];

export enum ResourceDocumentsColumns {
  Icon = 'Icon',
  Name = 'Name',
  Description = 'Description',
  Document = 'Document',
}

export const DEFAULT_RESOURCE_DOCUMENTS_COLUMNS: (keyof typeof ResourceDocumentsColumns)[] =
  ['Icon', 'Name', 'Description', 'Document'];

export enum HeatmapColumns {
  Actions = 'Actions',
  Name = 'Supplier Name',
  City = 'City',
  GUID = 'GUID',
  Score = 'Score',
  ResponseTime = 'Response Time',
}

export const DEFAULT_HEATMAP_COLUMNS: (keyof typeof HeatmapColumns)[] = [
  'Actions',
  'Name',
  'Score',
  'ResponseTime',
];

export enum QuotationRevisionTrackerInformationFields {
  Currency = 'Currency',
  TotalQuotationPrice = 'Total Quotation Price',
  TotalQuotationPriceUsd = 'Total Quotation Price (USD)',
  OfferedLeadTime = 'Offered Lead Time',
  IncotermsType = 'Incoterms Type',
  IncotermComment = 'Incoterm Comment',
  ValidUntil = 'Valid Until',
  Warranty = 'Warranty',
  PaymentTerms = 'Payment Terms',
  TechnicalCompliance = 'Technical Compliance',
  CommercialCompliance = 'Commercial Compliance',
  LogisticalCompliance = 'Logistical Compliance',
  Status = 'Status',
}

export const DEFAULT_QUOTATION_REVISION_TRACKER_INFORMATION_COLUMNS: (keyof typeof QuotationRevisionTrackerInformationFields)[] =
  [
    'Currency',
    'TotalQuotationPrice',
    'TotalQuotationPriceUsd',
    'OfferedLeadTime',
    'IncotermsType',
    'IncotermComment',
    'ValidUntil',
    'Warranty',
    'PaymentTerms',
    'TechnicalCompliance',
    'CommercialCompliance',
    'LogisticalCompliance',
    'Status',
  ];

export enum QuotationRevisionTrackerTechnicalRequirementsFields {
  TechnicalRequirementsCompliance = 'Technical Requirements Compliance',
  TechnicalSpecificationsOverall = 'Technical Specifications Overall',
  ScopeOfSupplyOverall = 'Scope Of Supply Overall',
  ScopeOfSupplyHardware = 'Scope Of Supply Hardware',
  ScopeOfSupplyServices = 'Scope Of Supply Services',
  AcceptanceCriteria = 'Acceptance Criteria',
  QualityControl = 'Quality Control',
  TechnicalDocumentation = 'Technical Documentation',
  SpecialTools = 'Special Tools',
  Hse = 'HSE',
  Communication = 'Communication',
  TechnicalRequirementSubmittals = 'Technical Requirement Submittals',
}

export const DEFAULT_QUOTATION_REVISION_TRACKER_TECHNICAL_REQUIREMENTS_COLUMNS: (keyof typeof QuotationRevisionTrackerTechnicalRequirementsFields)[] =
  [
    'TechnicalRequirementsCompliance',
    'TechnicalSpecificationsOverall',
    'ScopeOfSupplyOverall',
    'ScopeOfSupplyHardware',
    'ScopeOfSupplyServices',
    'AcceptanceCriteria',
    'QualityControl',
    'TechnicalDocumentation',
    'SpecialTools',
    'Hse',
    'Communication',
    'TechnicalRequirementSubmittals',
  ];

export enum QuotationRevisionTrackerCommercialRequirementsFields {
  CommercialRequirementsCompliance = 'CommercialRequirementsCompliance',
  CommercialRequirementsOverall = 'CommercialRequirementsOverall',
  NonDisclosureAgreement = 'Non-Disclosure Agreement',
  CommunicationModel = 'Communication Model',
  BiddingRulesForEngagedVendorsAndQAProcedure = 'Bidding Rules For Engaged Vendors And QA Procedure',
  Currency = 'Currency',
  PaymentSchedule = 'Payment Schedule',
  PaymentTerms = 'Payment Terms',
  SpecialPaymentTerms = 'Special Payment Terms',
  Warranty = 'Warranty',
  PlaceOfRepair = 'Place Of Repair',
  LiquidatedDamages = 'Liquidated Damages',
  CommercialRequirements = 'Commercial Requirements',
  GeneralTermsAndConditions = 'General Terms And Conditions',
  TermsAndConditions = 'Terms And Conditions',
  SpecificTermsAndConditionsRequirementSubmittals = 'Specific Terms And Conditions Requirement Submittals',
  CommercialRequirementSubmittals = 'Commercial Requirements Submittals',
}

export const DEFAULT_QUOTATION_REVISION_TRACKER_COMMERCIAL_REQUIREMENTS_COLUMNS: (keyof typeof QuotationRevisionTrackerCommercialRequirementsFields)[] =
  [
    'CommercialRequirementsCompliance',
    'CommercialRequirementsOverall',
    'NonDisclosureAgreement',
    'CommunicationModel',
    'BiddingRulesForEngagedVendorsAndQAProcedure',
    'Currency',
    'PaymentSchedule',
    'PaymentTerms',
    'SpecialPaymentTerms',
    'Warranty',
    'PlaceOfRepair',
    'LiquidatedDamages',
    'CommercialRequirements',
    'GeneralTermsAndConditions',
    'TermsAndConditions',
    'SpecificTermsAndConditionsRequirementSubmittals',
    'CommercialRequirementSubmittals',
  ];

export enum QuotationRevisionTrackerLogisticalRequirementsFields {
  LogisticalRequirementsCompliance = 'Logistical Requirements Compliance',
  LogisticalRequirementsOverall = 'Logistical Requirements Overall',
  IncotermsType = 'Incoterms Type',
  IncotermsComments = 'Incoterms Comments',
  PackagingAndMarking = 'Packaging And Marking',
  TradeAndMaterialCompliance = 'Trade And Material Compliance',
  LogisticsRequirements = 'Logistics Requirements',
  LogisticalRequirementSubmittals = 'Logistical Requirement Submittals',
}

export const DEFAULT_QUOTATION_REVISION_TRACKER_LOGISTICAL_REQUIREMENTS_COLUMNS: (keyof typeof QuotationRevisionTrackerLogisticalRequirementsFields)[] =
  [
    'LogisticalRequirementsCompliance',
    'LogisticalRequirementsOverall',
    'IncotermsType',
    'IncotermsComments',
    'PackagingAndMarking',
    'TradeAndMaterialCompliance',
    'LogisticsRequirements',
    'LogisticalRequirementSubmittals',
  ];

export enum QuotationRevisionTrackerTCOFields {
  Todo = 'Todo',
}

export const DEFAULT_QUOTATION_REVISION_TRACKER_TCO_COLUMNS: (keyof typeof QuotationRevisionTrackerTCOFields)[] =
  ['Todo'];
